import React, {Fragment} from "react";
import {Tab} from "@headlessui/react";
import Carbohydrates from "./Carbohydrates";
import CarbohydrateController from "../controllers/CarbohydrateController";
import {classes} from "./Leafs";


const CarbohydratesTabs: React.FC = () => {

    return (
        <Tab.Group>
            <Tab.List className="flex space-x-1 rounded-xl bg-primary-500 p-1">
                <Tab as={Fragment}>
                    {({selected}) => (
                        <button
                            className={classes(selected)}
                        >
                            GROUPED BY LOCATION
                        </button>
                    )}
                </Tab>
                <Tab as={Fragment}>
                    {({selected}) => (
                        <button
                            className={classes(selected)}
                        >
                            RESULTS
                        </button>
                    )}
                </Tab>
            </Tab.List>

            <Tab.Panels>
                <Tab.Panel>
                    <Carbohydrates paged={CarbohydrateController.pagedGroupedLocation} pagedExcel={CarbohydrateController.pagedExcelGroupedLocationFile}/>
                </Tab.Panel>
                <Tab.Panel>
                    <Carbohydrates paged={CarbohydrateController.paged} pagedExcel={CarbohydrateController.pagedExcel}/>
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    )
}

export default CarbohydratesTabs
