import React, {PropsWithChildren} from 'react'
import {classNames} from "../wrapper";

const SelectNumber = <TOptions, >(props: PropsWithChildren<{
    options: TOptions[];
    textFunc: (item: TOptions) => string;
    valueFunc: (item: TOptions) => number;

    value: number;
    onChange: (value: number) => void;
    className?: string;
    placeholder?: string;
}>) => {
    return <select className={classNames("input", props.className)} 
        onChange={e => props.onChange(parseInt(e.target.value))}
        value={props.value ?? 0}
    >
        <option value={0} disabled>{props.placeholder ?? "Choose an option"}</option>
        {props.options.map((l) => <option key={props.valueFunc(l)}
            value={props.valueFunc(l)}>{props.textFunc(l)}</option>)}
    </select>
}

export default SelectNumber
