import React, {useContext, useRef, useState} from "react";
import AppContext from "../appContext";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import CheckBox from "../components/CheckBox";
import UserDisplay from "../controllers/UserDisplay";
import Dialog from "../components/Dialog";
import {buildSetter} from "../immutableState";
import {EditRow, EditTable} from "../components/Fields";
import Input from "../components/Input";
import {useValidation} from "../validation";
import RoleController from "../controllers/RoleController";
import AgronomistController from "../controllers/AgronomistController";
import {useStateAjax} from "../wrapper";
import SelectNumberNullable from "../components/SelectNumberNullable";
import {showSuccessOrFailed} from "../Snacks";
import UserController from "../controllers/UserController";
import SelectNumberMultiple from "../components/SelectNumberMultiple";

function empty(): UserDisplay {
    return {
        name: '',
        active: false,
        id: 0,
        number: '',
        agronomistIds: [],
        archived: false,
        email: '',
        locked: false,
        roleId: null,
        whatsApp: false,
        emailPref: false
    }
}

const validateEmail = (email: string): boolean => {
    const result = email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
        )
    // do a backend call to see if the email exists or not
    return (result?.length ?? 0) > 0
}

const validationNumber = (number: string): boolean => {
    const result = number
        .toLowerCase()
        .match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
    )
    return (result?.length ?? 0) > 0
}

const Users: React.FC = () => {
    const context = useContext(AppContext);
    const [show, setShow] = useState(false)
    const [uniqueEmail, setUniqueEmail] = useState<boolean>(true)
    const [data, _setData] = useState<UserDisplay>(empty())
    const [setData] = buildSetter(data, _setData)
    const pagedTableRef = useRef<PagedTableFunctions<UserDisplay>>()

    function newUser() {
        setData(empty())
        setShow(true);
    }

    function editUser(row: UserDisplay) {
        setData(row);
        setShow(true);
    }

    function unique(email: string, id: number) {
        UserController.uniqueEmail({email, id}).then(resp => {
            setUniqueEmail(resp.data)
        })
        return uniqueEmail
    }

    function upsertPromise(data: UserDisplay) {
        const hideLoader = context.showLoader()
        UserController.uniqueEmail({email: data.email, id: data.id}).then(resp => {
            setUniqueEmail(resp.data)
            if (resp.data) {
                upsert(data)
                return
            }
            return
        }).finally(hideLoader)
    }

    function upsert(data: UserDisplay) {
        if (!validation.validate()) return;

        showSuccessOrFailed(context, UserController.upsert({
            agronomistIds: data.agronomistIds,
            email: data.email,
            number: data.number,
            id: data.id,
            name: data.name,
            roleId: data.roleId,
            emailPref: data.emailPref,
            whatsappPref: data.whatsApp
        }), 'User updated').then(() => {
            pagedTableRef.current?.refresh();
            setShow(false);
        });
    }

    const validation = useValidation({
        name: () => data.name.length > 0,
        // number: () => validationNumber(data.number),
        email: () => validateEmail(data.email)
    })

    const [roles,] = useStateAjax(RoleController.index, []);
    const [agronomist,] = useStateAjax(AgronomistController.index, []);

    function updateActive(row: UserDisplay) {
        // better user experience. Toggle UI immediately and then wait for network request
        pagedTableRef.current?.updateData(prev => {
            const found = prev.find(p => p.id === row.id)
            if (found)
                found.active = !found.active;
            return prev;
        });

        showSuccessOrFailed(context, UserController.quickUpdate({
            active: row.active,
            id: row.id
        })).finally(() => {
            pagedTableRef.current?.refresh();
        })
    }
    
    function deleteAgronomist(id: number){
        if(data.agronomistIds.includes(id)){
            const filtered = data.agronomistIds.filter(x => x !== id)
            setData({agronomistIds: []})
            setData( {agronomistIds: filtered})
        }
}
    function getRoleName(id: number | null): string {
        const role = roles.find(r => r.id === id)
        return role?.name ?? ''
    }

    return (
        <div className="bg-white p-2 shadow">
            <div className="btn btn-primary mb-2" onClick={newUser}>+</div>

            <PagedSearchTable componentRef={pagedTableRef} call={UserController.paged} keyExtractor={u => u.id}
                              columns={[
                                  {
                                      header: 'Email',
                                      key: 'email',
                                      row: (row, index) => row.email
                                  },
                                  {
                                      header: 'Role',
                                      key: 'role',
                                      row: (row, index) => getRoleName(row.roleId)
                                  },
                                  {
                                      header: 'Active',
                                      key: 'active',
                                      row: (row, index) => <CheckBox checked={row.active}
                                                                     onChange={() => updateActive(row)}/>
                                  },
                                  {
                                      header: <div className="text-right ml-2 ">Actions</div>,
                                      key: 'actions',
                                      row: (row, index) => <div className="w-full text-right"><div className=" ml-2 btn-primary btn-sm"
                                                                onClick={() => editUser(row)}>edit</div></div>
                                  },
                              ]}/>

            <Dialog title="User Profile" show={show} body={<>
                <h3 className="text-lg leading-6 font-medium text-gray-900 mx-4 my-2">
                    Personal Information
                </h3>

                <EditTable discard={() => setShow(false)} save={() => upsertPromise(data)}
                           saveWord={data.id === 0 ? 'insert' : 'update'}>
                    {EditRow('Email', <Input value={data.email}
                                             change={v => setData({email: v.trim()})}/>, validation.rules.email && uniqueEmail, !validateEmail(data.email) ? 'Valid email required' : 'Email already exists')}
                    {EditRow('Name', <Input value={data.name}
                                            change={v => setData({name: v})}/>, validation.rules.name, 'Name required')}
                    {EditRow('Number', <Input value={data.number} change={v => setData({number: v})} />)}

                    {EditRow('Role', <SelectNumberNullable options={roles} textFunc={r => r.name ?? ''}
                                                           valueFunc={r => r.id} value={data.roleId}
                                                           onChange={v => setData({roleId: v})}/>)}

                    {EditRow('Agronomist',
                        <SelectNumberMultiple options={agronomist} 
                                              textFunc={r => r.name ?? ''}
                                              valueFunc={r => r.id} 
                                              value={data.agronomistIds}
                                              onChange={v => {setData({agronomistIds:v? data.agronomistIds.concat(v) :data.agronomistIds})}}
                                              popupTitle={"Add Agronomist"}
                                              onDeleted={d =>deleteAgronomist(d)}/>)}


                    <div className="text-lg leading-6 font-medium text-gray-900 mt-4 mb-2">Communication preference:</div>
                    {EditRow('WhatsApp', <CheckBox checked={data.whatsApp}
                                                   onChange={v => setData({whatsApp: v})}/>)}
                    {EditRow('Email', <CheckBox checked={data.emailPref}
                                                   onChange={v => setData({emailPref: v})}/>)}
                </EditTable>
            </>
            } setShow={setShow}/>
        </div>
    )
}

export default Users;
