import React, {useContext, useEffect, useRef, useState} from "react";
import AppContext from "../../appContext";
import Language from "../../controllers/Language";
import PagedSearchTable, {PagedTableFunctions} from "../../components/PagedSearchTable";
import LanguageController from "../../controllers/LanguageController";
import Dialog from "../../components/Dialog";
import {EditRow, EditTable} from "../../components/Fields";
import {useValidation} from "../../validation";
import Input from "../../components/Input";
import {buildSetter} from "../../immutableState";
import {useMountedPromise} from "../lab/JobTable";
import {showSuccessOrFailed} from "../../Snacks";

const Languages: React.FC = () => {
    const context = useContext(AppContext);
    const {createPromise} = useMountedPromise();

    const [langModal, setLangModal] = useState<boolean>(false)
    const [lang, setLang] = useState<Language>({id: 0, name: ""})
    const pagedTableRef = useRef<PagedTableFunctions<Language>>()
    const [setData] = buildSetter(lang, setLang)
    const [languages, setLanguages] = useState<Language[]>([{id: 0, name: null}])
    const validation = useValidation({
        name: () => isUnique(lang.name, lang.id),
    });
    
    useEffect(() => {
        LanguageController.index().then(resp => {
            setLanguages(resp.data)
        })
    }, [])

    function isUnique(name: string | null, id: number) {
        if (name === null || name === '') return false;
        return !languages.some(languageName => {
            return languageName.name?.trim() === name.trim() && languageName.id !== id
        })
        
    }
    
    function addLanguage() {
        setLang({id: 0, name: ""})
        setLangModal(true)
    }

    function update(row: Language) {
        setLang({
            id: row.id,
            name: row.name
        })
        setLangModal(true);
    }

    function upsertLang() {
        if (!validation.validate()) {
            return;
        }

        createPromise(showSuccessOrFailed(context, LanguageController.upsertLanguage(lang))).then(resp => {
            setLangModal(false);
            pagedTableRef.current?.refresh();
        }).finally(() => setLang({id: 0, name: ""}));
    }

    return (
        <div>
            <div className="pb-2">
                <div className="btn bg-primary m-2" onClick={addLanguage}>
                    Add Language
                </div>
                
            </div>
            <PagedSearchTable componentRef={pagedTableRef} call={LanguageController.languages} keyExtractor={u => u.id} 
                              columns={[
                                  {
                                      header: 'Name',
                                      key: 'name',
                                      row: (row, index) => row.name
                                  },
                                  {
                                      header: <div className='text-right'>Actions</div>,
                                      key: 'actions',
                                      row: (row, index) => <div className="text-right"><div className="btn-primary btn-sm" 
                                                                onClick={() => update(row)}>Edit</div></div>
                                  },
            ]}/>

            <Dialog title={"Language"}
                    show={langModal}
                    setShow={setLangModal}
                    body={<>
                        <EditTable discard={() => setLangModal(false)} 
                                   save={() => upsertLang()} 
                                   saveWord={lang.id ? 'update' : 'add'}>
                            {EditRow('Name', <Input value={lang.name ?? ''}
                                                change={v => setData({name: v})}/>, validation.rules.name, 'Unique name required')}

                        </EditTable>
                        </>} 
            />
            
        </div>
    )
}

export default Languages;