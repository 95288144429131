import React, {PropsWithChildren, useState} from 'react'
import {classNames} from "../wrapper";
import Dialog from "./Dialog";
import {EditRow, EditTable} from "./Fields";
import WarningPopup, {useWarningState} from "./WarningPopup";
import warningPopup from "./WarningPopup";

const SelectNumberMultiple = <TOptions, >(props: PropsWithChildren<{
    options: TOptions[];
    textFunc: (item: TOptions) => string;
    valueFunc: (item: TOptions) => number;

    value: number[];
    onChange: (value: number[]) => void;
    className?: string;
    onDeleted: (value: number) => void;
    popupTitle?: string
}>) => {
    const warningState = useWarningState<number>(0)
    const [show, setShow] = useState(false)
    const [selectedOption, setSelectedOption] = useState<string | null>(null)
    function add(id: number) {
        if (!props.value.includes(id)) {
            props.onChange([id])
        }
    }
    
    return <div className="flex-col items-end justify-end text-sm p-1">
        {props.value.map((l) => <div key={l}>
            {props.options.map((o) => {
                if (props.valueFunc(o) === l) {
                    return <div className="flex items-center justify-end text-sm pb-1 " key={props.valueFunc(o)}>
                        <div>{props.textFunc(o)}</div>
                        <button className="btn-sm btn-error ml-1" onClick={() => {
                            warningState.show(`Are you sure you want to remove ${props.textFunc(o)}?`, props.valueFunc(o))
                        }}>
                            x
                        </button>
                    </div>
                }
            })}</div>)}


        <div className="flex items-center justify-end text-sm">
            <button className="btn-sm btn-primary ml-1 " onClick={() => {
                setShow(true)
            }}>
                +
            </button>
        </div>
        
        <Dialog title={props.popupTitle} 
                show={show}
                setShow={setShow}
                body={
                        <EditTable 
                            discard={() => {
                                setShow(false)
                                setSelectedOption(null)
                            }} 
                            save={() => {
                                if (selectedOption != null) {
                                    add(parseInt(selectedOption))
                                    setSelectedOption(null)
                                }
                                setShow(false)
                            }}
                            saveWord={"add"}>
                            {
                                EditRow("Agronomist", <select className={classNames("input", props.className)}
                                                       onChange={e => setSelectedOption(e.target.value)}
                                >
                                    <option value={0} disabled>Choose an option</option>
                                    {props.options.map((l) => <option key={props.valueFunc(l)}
                                                                      value={props.valueFunc(l)}>{props.textFunc(l)}</option>)}
                                </select>)
                            }
                        </EditTable>
                }
        />
        <WarningPopup state={warningState} onYes={() => props.onDeleted(warningState.state.data)}/>
    </div>
}

export default SelectNumberMultiple
